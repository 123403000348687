import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import Link from "../components/UI-Elements/Navigation/Link";
import { instagramLink } from "../common/helpers/config";
import Title from "@components/UI-Elements/Typography/Title";
import { Blockquote } from "@components/UI-Elements/Typography/Text";

const Section: React.FC = ({ children }) => (
  <section className="flex flex-col px-8 py-7 md:flex-row">{children}</section>
);

const AboutPage: React.FC = () => {
  return (
    <Layout prose>
      <SEO
        keywords={["über", "about"]}
        title="Über"
        description="Mein Name ist Mathias. Ich habe Medieninformatik studiert und arbeite als Softwareentwickler. Ich beschäftige seit 2017 mit dem
            Thema Bitcoin und Blockchain und finde das Thema bis heute noch faszinierend."
      />
      <Title level={1}>Wer steckt hinter einfachcrypto.de?</Title>
      <Section>
        <div className="w-full text-center md:w-64">
          <div className="mx-auto px-8 py-4">
            <StaticImage
              src="../images/about/mathias-image.png"
              alt="Image of Mathias Michel"
              className="mx-auto block w-48"
            />
          </div>
        </div>
        <div className="pl-4">
          <Title
            level={2}
            className="w-full text-center text-base md:text-left"
          >
            Mathias Michel
          </Title>
          <p>
            Mein Name ist Mathias. Ich habe Medieninformatik studiert und
            arbeite als Softwareentwickler. Ich beschäftige mich seit 2017 mit
            dem Thema Bitcoin und Blockchain und bin bis heute noch fasziniert.
            Ich nutze diese Seite, um Informationen und Hilfsmittel für die
            Recherche mit dir zu teilen.
          </p>
          <p className="py-4">
            Du hast Fragen oder Anregungen? Schreib mir gerne über das{" "}
            <Link href="/contact/" type="text">
              Kontaktformular
            </Link>{" "}
            oder auf{" "}
            <Link href={instagramLink} type="text" external>
              Instagram
            </Link>
            .
          </p>
        </div>
      </Section>
      <Section>
        <h3 className="mt-2 w-64 text-xl font-bold">
          Was ist das Ziel dieser Seite?
        </h3>
        <ul className="flex-1 list-outside list-disc pl-6">
          <li>
            Zum einen ist diese Seite ein öffentliches Linkverzeichnis zum Thema
            Bitcoin und Kryptowährungen. Seiten oder Projekte, die ich auf
            meinen täglichen Streifzügen durchs World Wide Web finde, teile ich
            hier mit dir.
          </li>
          <li>
            Ich erstelle hier Tools oder Dashboards, die zur besseren
            Entscheidungsfindung über die aktuellen Lage helfen.
          </li>
          <li>
            Vereinzelt werde ich hier auch Anleitungen oder Erklärungen
            schreiben. Da es aber bereits viele gute Artikel gibt, verweise ich
            lieber auf diese.
          </li>
        </ul>
      </Section>
      <br />
      <Section>
        <h3 className="mt-2 w-64 text-xl font-bold">Meine Werte & Ansichten</h3>
        <ul className="flex-1 list-outside list-disc pl-6">
          <li>
            Die Bitcoin und die Blockchain-Technologie sind vergleichbar mit dem
            Internet. Irgendwann werden wir beides ganz selbstverständlich in
            unserem täglichen Leben nutzen.
          </li>
          <li>
            Ich betreibe kein Daytrading, sondern verfolge eine langfristige
            Strategie durch Dollar-Cost Averaging.
          </li>
          <li>
            Du kannst den Markt nicht timen, aber du kannst die Temperatur
            messen. Damit meine ich nicht Technische Chartanalyse, sondern durch
            Markt und On-Chain Daten die aktuelle Gefühlslage zu bestimmen.{" "}
          </li>
        </ul>
      </Section>

      <Section>
        <div className="flex-1 md:mr-8 md:w-2/3">
          <Blockquote className="border-l-4 border-gray-900 pl-4 text-justify font-serif leading-loose">
            Bitcoin ist resistent. Bitcoin ist prinzipienfest. BTC ist den
            Idealen des Internets verpflichtet. Ich denke, es hat die größten
            Chancen, die native Währung des Internets zu werden. Wenn man sich
            das Internet wie ein Land vorstellt, ist das eine große Chance.
          </Blockquote>

          <cite className="mt-4 block text-right text-xs font-bold uppercase">
            – Jack Dorsey
          </cite>
        </div>
      </Section>
    </Layout>
  );
};

export default AboutPage;
